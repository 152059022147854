<template>
  <CRow class="d-flex justify-content-center">
    <CCol md="12" class="text-center text-black">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md="12" class="text-center">
              <h2 class="mt-3 font-weight-bold text-black">{{ $t('GenerateQRCode') }}</h2>
              <h5 class="mt-3">{{ $t('ForQROrder') }}</h5>
              <h6 class="text-description">
                {{ $t('QROrderDescription') }}
              </h6>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol md="12">
              <CJumbotron color="white" border-color="grey-200">
                <p class="text-black font-weight-bold text-left">{{ $t('selectshop') }}</p>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text"> {{ $t('selectshop') }} </label>
                  </div>
                  <select v-if="cashierAuth == 'null'" class="custom-select" v-model="shopObjectId">
                    <option v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
                      {{ shop.branchName }}
                    </option>
                  </select>

                  <select v-else class="custom-select" v-model="shopObjectId" disabled>
                    <option :value="shop.objectId">
                      {{ shop.branchName }}
                    </option>
                  </select>
                </div>
                <div v-if="tableLayoutEnabled" class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text"> {{ $t('selectZone') }} </label>
                  </div>
                  <select class="custom-select" v-model="selectedZoneIndex">
                    <option v-for="(zone, index) in tableLayout" :key="zone.zoneObjectId" :value="index">
                      {{ zone.zoneName }}
                    </option>
                  </select>
                </div>

                <p class="text-black font-weight-bold text-left m-0">
                  {{ $t('SelectTable') }}
                </p>
                <p class="p-0 text-danger font-weight-bold text-left" style="font-size: 12px">
                  {{ tableAlert }}
                </p>
                <!--  <CRow>
                  <CCard
                    v-for="table in tables"
                    :key="table.objectId"
                    class="col-sm-2"
                  >
                    <CButton
                      v-if="table.objectId === tableObjectId"
                      @click="selectTable(table)"
                      style="padding: 15px"
                      class="btn-success"
                    >
                      <small class="text-white">
                        <i class="fas fa-circle" style="font-size: 6px"></i>
                      </small>
                      <h6 class="font-weight-bold text-white">
                        {{ table.name }}
                      </h6>
                    </CButton>
                    <CButton
                      v-else
                      @click="selectTable(table)"
                      style="padding: 15px; border-color: #e5e5e5"
                    >
                      <small class="text-white" v-if="table.tableStatus == '0'">
                        <i class="fas fa-circle" style="font-size: 6px"></i>
                      </small>
                      <small class="text-danger" v-else>
                        <i class="fas fa-circle" style="font-size: 6px"></i>
                      </small>
                      <p v-if="table.currentReceipt != null">
                        {{ convertCurrency(table.currentReceipt.grandTotal) }}
                      </p>

                      <h6 class="font-weight-bold text-black">
                        {{ table.name }}
                      </h6>
                    </CButton>
                  </CCard>
                </CRow> -->
                <CRow>
                  <CCard v-for="table in tables" :key="table.objectId" class="col-sm-3 border-0">
                    <CButton v-if="table.objectId === tableObjectId" @click="selectTable(table)"
                      style="padding: 15px; min-height: 90px" class="btn-success">
                      <div v-if="table.currentReceipt != null">
                        <div v-if="table.tableStatus == '1'">
                          <small class="text-danger float-left">
                            <i class="fas fa-circle" style="font-size: 6px"></i>
                          </small>
                          <span class="pr-2">
                            {{ table.name }}
                          </span>
                        </div>
                        <div v-else>
                          <span>
                            {{ table.name }}
                          </span>
                        </div>
                        <hr class="mt-0" />
                        <h6 v-if="table.currentReceipt != null" class="font-weight-bold">
                          {{ convertCurrency(table.currentReceipt.grandTotal) }}
                        </h6>
                      </div>
                      <div v-else>
                        <h4>{{ table.name }}</h4>
                      </div>
                      <div v-if="isStatic">
                        <template v-if="staticQRCondition.tableStatusRequired">
                          <CBadge v-if="getStatus(table)" style="font-size: 10px;" class="p-1" color="danger">
                            <i class="fi fi-rr-lock"></i>
                          </CBadge>
                          <CBadge v-else style="font-size: 10px;" class="p-1" color="light"><i
                              class="fi fi-rr-lock-open-alt"></i></CBadge>
                          &nbsp;
                        </template>
                        <CBadge v-if="getPIN(table) !== '' && staticQRCondition.pinRequired" style="font-size: 12px;"
                          class="p-1" color="light">
                          PIN :
                          {{ getPIN(table) }}
                        </CBadge>
                      </div>
                    </CButton>
                    <CButton v-else @click="selectTable(table)"
                      style="padding: 15px; border-color: #e5e5e5;min-height: 90px; ">
                      <template v-if="table.tableStatus !== 3">
                        <div v-if="table.currentReceipt != null" class="text-black">
                          <div v-if="table.tableStatus == '1'">
                            <small class="text-danger float-left">
                              <i class="fas fa-circle" style="font-size: 6px"></i>
                            </small>
                            <span class="pr-2">
                              {{ table.name }}
                            </span>
                          </div>
                          <div v-else>
                            <span>
                              {{ table.name }}
                            </span>
                          </div>
                          <hr class="mt-0" />
                          <template></template>
                          <h6 v-if="table.currentReceipt != null" class="font-weight-bold text-dark">
                            {{ convertCurrency(table.currentReceipt.grandTotal) }}
                          </h6>
                        </div>
                        <div v-else>
                          <h4 class="text-grey">{{ table.name }}</h4>
                        </div>
                        <div v-if="isStatic">
                          <template v-if="staticQRCondition.tableStatusRequired">
                            <CBadge v-if="getStatus(table)" style="font-size: 10px;" class="p-1" color="danger">
                              <i class="fi fi-rr-lock"></i>
                            </CBadge>
                            <CBadge v-else style="font-size: 10px;" class="p-1" color="success"><i
                                class="fi fi-rr-lock-open-alt"></i></CBadge>
                            &nbsp;
                          </template>
                          <CBadge v-if="getPIN(table) !== '' && staticQRCondition.pinRequired" style="font-size: 12px;"
                            class="p-1" color="light">PIN :
                            {{ getPIN(table) }}
                          </CBadge>
                        </div>
                      </template>
                      <div v-else>
                        <h6 class="text-grey">{{ table.name }}</h6>
                        <hr class="mt-0" />

                        {{ $t('paidSuccess') }}


                      </div>

                    </CButton>
                  </CCard>
                </CRow>
                <div class="form-group row mt-3">
                  <label class="col-sm-5 col-form-label text-left">
                    <strong>{{ $t('numberOfCustomer') }}</strong>
                    <br />
                    <small class="text-description float-left">
                      {{ $t('numberOfCustomerDescription') }}
                    </small>
                  </label>
                  <div class="col-sm-7 mt-2">
                    <input type="number" v-model.number="pax" class="form-control" min="1" inputmode="numeric" />
                  </div>
                </div>

                <button class="btn btn-dark btn-block" @click="generateQR()">
                  {{ $t('GenerateQRCode') }}
                </button>
              </CJumbotron>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CModal :show.sync="canvasShow" color="white" centered :footer="footer">
        <CCol md="12">
          <p class="text-black text-left">
            <strong> {{ $t('tableNumber') }} : {{ tableName }} </strong>
            ( {{ pax }} {{ $t('guest') }} )
          </p>
          <CJumbotron color="white" border-color="grey-200" class="col-md-12 mx-auto text-black text-left">
            <h4 class="font-weight-bold ml-3">{{ $t('billOrder') }}</h4>
            <h6 class="ml-3">
              <strong>{{ shopName }} </strong> ( {{ branchName }} )
            </h6>
            <hr />
            <h6 class="ml-3">
              {{ $t('table') }} :
              <strong class="h1">{{ tableName }}</strong>
            </h6>

            <h6 class="text-left ml-3">
              {{ $t('date') }} : <strong> {{ datetime }} </strong>
            </h6>

            <div v-if="loadingQR" class="w-100 d-flex align-items-center justify-content-center"
              style="aspect-ratio: 1/1;">
              <!-- <CSpinner color="success" size="md" variant="grow" /> -->
            </div>
            <vue-qrcode v-else class="img-fluid" :value="qrtable" style="width: 100%" />
            <br />
            <p class="text-center">{{ $t('billOrderDialog') }}</p>

            <h6 class="font-weight-bold text-center">
              {{ $t('billOrderDialog2') }}
            </h6>
          </CJumbotron>
        </CCol>
        <template #footer-wrapper>
          <CRow class="justify-content-center"></CRow>
        </template>
      </CModal>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import VueQrcode from 'vue-qrcode'
import moment from 'moment'
import util from '@/util/util'
import poscrm from '@/services/poscrm'
import { CButton, CSpinner } from '@coreui/vue'

export default {
  components: { VueQrcode },
  data() {
    return {
      logo: '../../logo.png',
      tables: [],
      qrtable: '',
      tableName: '',
      tableObjectId: '',
      loadingButton: false,
      pax: 1,
      canvasShow: false,
      footer: '',
      tableAlert: '',
      isStatic: false,
      staticQRCondition: {},
      loadingQR: false,
      tableLayoutEnabled: false,
      tableLayout: {},
      selectedZoneIndex: 0,
      qrType: '',
    }
  },
  computed: {
    ...mapGetters(['shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    cashierAuth() {
      return `${localStorage.getItem('x-auth')}`
    },
    shopObjectId: {
      get() {
        if (this.cashierAuth != 'null') {
          return `${localStorage.getItem('x-shopObjectId')}`
        } else {
          return this.$store.getters.shopObjectId
        }
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    shop() {
      return this.shops.find((detail) => detail.objectId === this.shopObjectId)
    },
    shopName() {
      if (this.shop) {
        return this.shop.shopName
      } else {
        return ''
      }
    },
    branchName() {
      if (this.shop) {
        return this.shop.branchName
      } else {
        return ''
      }
    },
    datetime() {
      let date = new Date()
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
  },
  created() {
    this.getTable()

  },
  methods: {
    convertCurrency(num) {
      if (typeof num == 'number') {
        return '฿' + util.convertCurrency(Number(num))
      } else {
        return ''
      }
    },
    selectTable(table) {
      this.canvasShow = false
      this.tableName = table.name
      this.tableObjectId = table.objectId
    },
    async generateQR() {
      this.loadingQR = true
      if (this.tableObjectId === '') {
        this.tableAlert = this.$i18n.t('selectTableAleart')
      } else {
        this.tableAlert = ''
        this.canvasShow = true

        // if (this.qrType == 'STATIC') {
        if (false) {
          let mainurl = process.env.VUE_APP_WEB_URL + '/dine-in/home?'
          const d = new Date()
          let time = d.getTime()

          this.qrtable =
            mainurl +
            'uid=' +
            this.uid +
            '&shopObjectId=' +
            this.shopObjectId +
            '&tableObjectId=' +
            this.tableObjectId +
            '&pax=' +
            this.pax

          this.loadingQR = false

        } else {
          // console.log(this.qrtable)
          let data = {
            uid: this.uid,
            shopObjectId: this.shopObjectId,
            tableObjectId: this.tableObjectId,
            numCust: this.pax
          }
          await pos({
            method: 'post',
            url: '/api/v1.0/selforder/generateqrotp',
            data: data,
            headers: { shopObjectId: this.shopObjectId }
          }).then((response) => {
            console.log("response : ", response)
            if (response.data?.error?.code == 0) {
              this.qrtable = response.data?.data?.url || ''
              this.loadingQR = false
            }
          })

        }
      }
    },
    getTable() {
      let params = {
        shopObjectId: this.shopObjectId,
      }

      pos({
        method: 'get',
        url: '/api/v1.0/' + this.uid + '/Shop/gettablefb/data',
        params: params,
      }).then((response) => {
        this.tables = response.data?.data || []
        console.log(this.tables)
        this.getOrderShopSetting()
      })
    },
    getPIN(table) {
      let pin = table.lockedBy?.pin || ''
      return pin
    },
    getStatus(table) {
      return table.lockedBy?.cashier
    },
    async getOrderShopSetting() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      let params = {
        shopObjectId: shopObjectId,
      }
      try {
        await poscrm({
          method: 'get',
          url: '/api/v2.1/' + uid + '/OrderShopSetting',
          params: params,
        }).then((res) => {
          let orderShopSetting = res.data?.data[0] || {}
          this.tableLayoutEnabled = orderShopSetting?.tableLayout?.enabled || false
          this.qrType = orderShopSetting.qrType

          if (this.tableLayoutEnabled == true) {
            this.tableLayout = this.organizeTableLayout(this.tables)
            this.tables = this.tableLayout[this.selectedZoneIndex].tables
            console.log('this.tableLayout : ', this.tableLayout)
          }
          if (orderShopSetting.qrType == "STATIC") {
            this.isStatic = true
            this.staticQRCondition = orderShopSetting?.staticQRCondition || {}
          }

        })

      } catch (error) {

      }
    },
    organizeTableLayout(tables) {
      let tableLayout = [];
      let groupedTables = tables.reduce((acc, table) => {
        if (!acc[table.zoneObjectId]) {
          acc[table.zoneObjectId] = {
            zoneName: table.zoneName || "Unknown Zone",
            zoneObjectId: table.zoneObjectId || "Unknown Zone",
            tables: []
          };
        }
        acc[table.zoneObjectId].tables.push(table);
        return acc;
      }, {});
      tableLayout = Object.values(groupedTables);
      return tableLayout;
    },

  },
  watch: {
    shopObjectId() {
      this.getTable();
    },
    selectedZoneIndex(newIndex) {
      if (this.tableLayout.length > 0 && newIndex >= 0) {
        this.tables = this.tableLayout[newIndex].tables || [];
      } else {
        this.tables = [];
      }
    }
  }
}
</script>
<style></style>
